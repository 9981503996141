import * as jwt_decode from "jwt-decode";

export const getUserDetails = (token: string) => {
  try {
    let decodedToken: any = jwt_decode(token);
    return decodedToken;
  } catch (error) {
    redirectToLogin();
  }
};

export const redirectToLogin = () => {
  localStorage.removeItem("_lay_sess");
  localStorage.removeItem("$crt");
  const checkRedirectionRule =
    window.location.pathname.includes("account/booking");
  window.location.href = checkRedirectionRule ? "/manage-booking" : "/";
};

export const getLoginUserInfo = () => {
  let token: any = localStorage.getItem("_lay_sess");
  if (token) {
    try {
      let decodedToken: any = jwt_decode(token);
      return decodedToken;
    } catch (error) {
      return {};
    }
  }
  return {};
};
